module.exports = [{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-remark-images@7.12.3_43ye7q67mque5iudmrihj2xfny/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"markdownCaptions":true,"withWebp":true,"showCaptions":["title"],"maxWidth":650,"linkImagesToOriginal":true,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-intl@5.10.0_rjpej3jaontwrr2rdmzwveqnn4/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"fallbackLanguage":"en","defaultLanguage":"en","path":"/opt/atlassian/pipelines/agent/build/sites/site-v1/locales/","redirect":false,"redirectDefaultLanguageToRoot":true},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-catch-links@5.12.0_gatsby@5.10.0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../plugins/source-traffit/gatsby-browser.js'),
      options: {"plugins":[],"traffitAccountName":"cloudtechconsulting"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby@5.10.0_ou3kqzsalsrbhdebjezgcw6vny/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
