import React from 'react';
// import Seo from '../src/components/Seo';
import { Footer } from '../src/components/Footer';
import { Header } from '../src/components/Header';
// import { ContactUsMobile } from '../src/components/ContactUsMobile/ContactUsMobile';

interface IStandardLayoutProps {
  children: JSX.Element;
}

const StandardLayout = ({ children }: IStandardLayoutProps) => {
  return (
    <>
      <Header />
      <div id="content" className="site-content">
        {children}
      </div>
      <Footer />
    </>
  );
};

export default StandardLayout;
