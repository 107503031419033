exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-job-offers-tsx": () => import("./../../../src/pages/job-offers.tsx" /* webpackChunkName: "component---src-pages-job-offers-tsx" */),
  "component---src-pages-offer-application-development-tsx": () => import("./../../../src/pages/offer/application-development.tsx" /* webpackChunkName: "component---src-pages-offer-application-development-tsx" */),
  "component---src-pages-offer-cloud-consulting-tsx": () => import("./../../../src/pages/offer/cloud-consulting.tsx" /* webpackChunkName: "component---src-pages-offer-cloud-consulting-tsx" */),
  "component---src-pages-offer-digital-transformation-tsx": () => import("./../../../src/pages/offer/digital-transformation.tsx" /* webpackChunkName: "component---src-pages-offer-digital-transformation-tsx" */),
  "component---src-pages-offer-engineering-team-management-tsx": () => import("./../../../src/pages/offer/engineering-team-management.tsx" /* webpackChunkName: "component---src-pages-offer-engineering-team-management-tsx" */),
  "component---src-pages-offer-it-recruitment-tsx": () => import("./../../../src/pages/offer/it-recruitment.tsx" /* webpackChunkName: "component---src-pages-offer-it-recruitment-tsx" */),
  "component---src-pages-offer-it-trainings-tsx": () => import("./../../../src/pages/offer/it-trainings.tsx" /* webpackChunkName: "component---src-pages-offer-it-trainings-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

