import React from 'react';
import { Link } from 'gatsby';

export const Menu = () => {
  return (
    <div className="octf-col menu-col">
      <nav id="site-navigation" className="main-navigation">
        <ul className="menu">
          <li className="">
            <Link to="/">Home</Link>
          </li>
          <li className="menu-item-has-children">
            <a href="#">Our Offer</a>
            <ul className="sub-menu">
              <li>
                <Link to="/offer/cloud-consulting">Cloud Consulting</Link>
              </li>
              <li>
                <Link to="/offer/digital-transformation">Digital Transformation</Link>
              </li>
              <li>
                <Link to="/offer/it-recruitment">IT Recruitment</Link>
              </li>
              <li>
                <Link to="/offer/application-development">Application Development</Link>
              </li>
              <li>
                <Link to="/offer/engineering-team-management">Engineering Team Management</Link>
              </li>
              <li>
                <Link to="/offer/it-trainings">IT Trainings</Link>
              </li>
            </ul>
          </li>
          <li className="">
            <Link to="/job-offers">Job Offers</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
